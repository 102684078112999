.bg {
  height: 100vh;
  width: 100vw;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.quote-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 1em
}

.quote {
  display: flex;
  align-items: center;
  text-align: center;;
}

.quote-text {
  position: relative;
  padding: 2em;
}

.quote-text::before {
  position: absolute;
  background-image: url('/public/images/quote-mark-open.png');
  background-size: contain;
  background-repeat: no-repeat;
  display: inline-block;
  width: 50px;
  height: 50px;
  margin-left: -35px;
  margin-top: -20px;
  content:"";
}

.quote-text::after {
  position: absolute;
  background-image: url('/public/images/quote-mark-close.png');
  background-size: contain;
  background-repeat: no-repeat;
  display: inline-block;
  width: 50px;
  height: 50px;
  margin-left: -15px;
  margin-top: -20px;
  content:"";
}

.quote img {
  height: 50px;
  margin-right: 10px;
}

.quote p {
  font-size: 20px;
  font-style: italic;
  margin: 0;
}

.source {
  font-size: 16px;
  font-style: normal;
  margin-top: 10px;
}
